import PrimeVue from "primevue/config";
import Aura from "@primevue/themes/aura";
import { definePreset } from "@primevue/themes";
import DefaultTheme from "../../Layout/src/assets/default-theme";

import ProgressSpinner from "primevue/progressspinner";
import Button from "primevue/button";
import Card from "primevue/card";
import Divider from "primevue/divider";
import Chart from "primevue/chart";
import SplitButton from "primevue/splitbutton";
import Badge from "primevue/badge";

import { createApp } from "vue";
import { i18n } from "./locales/i18n";

const MyPreset = definePreset(Aura, { DefaultTheme });

export default function configureApp(app: ReturnType<typeof createApp>) {
  app.use(PrimeVue, {
    theme: {
      preset: MyPreset,
      options: {
        prefix: "p",
        darkModeSelector: ".dark",
        cssLayer: {
          name: "primevue",
          order: "tailwind-base, primevue, tailwind-utilities",
        },
      },
    },
  });
  app.use(i18n);

  app.component("ProgressSpinner", ProgressSpinner);
  app.component("Button", Button);
  app.component("Card", Card);
  app.component("Divider", Divider);
  app.component("Chart", Chart);
  app.component("SplitButton", SplitButton);
  app.component("Badge", Badge);
}
